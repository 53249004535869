import { compact } from '@/utils/Helpers'
import { ReviewCard } from '../testimonial/ReviewCard'
import { ComponentFeaturedReviews } from 'types/generated/contentful-types'
import ForaSwiper from '../swiper/ForaSwiper'
import styles from '../swiper/components/featured-reviews.module.css'
import Image from 'next/image'

export default function FeaturedReviews({
  heading,
  testimonialsCollection,
  background,
  cloudinaryBackground,
  sectionId,
}: ComponentFeaturedReviews): JSX.Element {
  return (
    <section
      id={sectionId ?? undefined}
      className="relative grid py-12 place-items-center place-content-center lg:block lg:py-22"
    >
      <h2 className="relative z-10 mx-auto mb-8 lg:mb-16 text-center text-sand lg:w-[calc(100%-320px)] lg:max-w-4xl fora-text-h3">
        {heading}
      </h2>
      <div className="z-10 w-screen max-w-screen-xl pl-4 lg:w-full lg:pl-0">
        <ForaSwiper
          className={styles.foraSwiper}
          params={{
            navigation: true,
            pagination: true,
            keyboard: true,
            slidesPerView: 1.08,
            spaceBetween: 8,
            centeredSlides: false,
            breakpoints: {
              1024: {
                slidesPerView: 1,
                centeredSlides: true,
              },
            },
          }}
        >
          {compact(testimonialsCollection?.items).map((testimonial, i) => (
            <div
              key={'review' + i}
              className="w-full h-full p-8 lg:w-[calc(100%-320px)] lg:mx-auto bg-shell"
            >
              <ReviewCard {...testimonial} parent="FeaturedReviews" />
            </div>
          ))}
        </ForaSwiper>
      </div>
      <BackgroundAsset
        background={background}
        cloudinaryBackground={cloudinaryBackground}
      />
    </section>
  )
}

type BackgroundAssetProps = Pick<
  ComponentFeaturedReviews,
  'background' | 'cloudinaryBackground'
>

const BackgroundAsset = ({
  background,
  cloudinaryBackground,
}: BackgroundAssetProps) => {
  const isVideo = background?.contentType === 'video/mp4'

  return isVideo ? (
    <VideoAsset
      background={background}
      cloudinaryBackground={cloudinaryBackground}
    />
  ) : (
    <ImageAsset
      background={background}
      cloudinaryBackground={cloudinaryBackground}
    />
  )
}

type VideoAssetProps = Pick<
  ComponentFeaturedReviews,
  'background' | 'cloudinaryBackground'
> & {
  className?: string
}

const VideoAsset = ({
  background,
  className,
  cloudinaryBackground,
}: VideoAssetProps) => {
  const videoUrl =
    cloudinaryBackground && cloudinaryBackground.length > 0
      ? cloudinaryBackground[0].secure_url
      : background && background.url
      ? background.url
      : null

  return (
    <div
      className={`absolute h-full w-screen max-w-screen-xl lg:w-full lg:inset-0 overflow-hidden ${className}`}
      data-name="background"
    >
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline
        controls={false}
        className="absolute w-[inherit] h-[inherit] inset-x-0 object-cover object-center -translate-y-1/2 top-1/2"
      >
        {videoUrl && <source src={videoUrl} />}
      </video>
    </div>
  )
}

type ImageAssetProps = Pick<
  ComponentFeaturedReviews,
  'background' | 'cloudinaryBackground'
> & {
  className?: string
}

const ImageAsset = ({
  background,
  className,
  cloudinaryBackground,
}: ImageAssetProps) => {
  return (
    <div
      className={`absolute h-full w-screen max-w-screen-xl lg:w-full lg:inset-0 overflow-hidden ${className}`}
      data-name="background"
    >
      <Image
        src={cloudinaryBackground?.[0]?.secure_url ?? background?.url ?? ''}
        data-src={
          cloudinaryBackground?.[0]?.secure_url ?? background?.url ?? ''
        }
        alt={
          cloudinaryBackground?.[0]?.metadata.alt ||
          background?.description ||
          ''
        }
        quality={90}
        fill
        style={{ objectFit: 'cover' }}
      />
    </div>
  )
}
